<template>
  <page-container title="行政区域划分" :breadcrumb="breadcrumb">
    <div class="dept-container">
      <div class="box-container" style="margin-top: 24px; width: 320px; padding-bottom: 24px;">
        <div class="box-container-inner" style="height: calc(100vh - 200px); overflow: auto;">
          <div class="tree-top">
            <a-button type="primary" @click="addNewDept">新增</a-button>
          </div>
          <a-tree
            :selected-keys.sync="selectedKeys"
            :replace-fields="{children:'children', title:'areaname', key:'areacode'}"
            :tree-data="adminDivisionTree"
            :load-data="onLoadData"
            @select="selectDept"
          >
            <span slot="customTreeNode" slot-scope="node">
              <span>{{node.dataRef.areaname}}</span>
              <a-tooltip v-if="!node.dataRef.isLeaf">
                <template slot="title">新增下级机构</template>
                <a-icon type="plus" @click.stop="addChildQuick(node)" style="margin-left: 7px;" />
              </a-tooltip>
              <a-tooltip>
                <template slot="title">删除机构</template>
                <a-icon type="delete" @click.stop="deleteQuick(node)" style="margin-left: 7px;" />
              </a-tooltip>
            </span>
          </a-tree>
        </div>
      </div>
      <div class="box-container" style="margin-top: 24px; flex: 1; padding-bottom: 24px; padding-left: 0;">
        <div class="box-container-inner" style="height: calc(100vh - 200px); overflow-y: auto;">
          <a-form-model ref="userDeptForm" :model="formDatas" :rules="formRules" :label-col="{span:6}" :wrapper-col="{span:14}">
            <a-form-model-item :wrapper-col="{ span: 22, offset: 0 }" style="width: 100%; margin-bottom: 20px; overflow: hidden;">
              <a-button v-if="formType=='edit'&&formDatas.parentcode.length<4" size="large" @click="addChild" style="margin-left: 10px;float: right;"><a-icon type="plus"></a-icon>新增下级机构</a-button>
              <a-button v-if="formType=='edit'" size="large" type="danger" @click="deleteConfirm" style="margin-left: 10px; float: right;">删除</a-button>
              <a-button style="float: right;" v-if="selectedItem||formType=='addNew'" size="large" type="primary" @click="save">保存</a-button>
            </a-form-model-item>
            <a-form-model-item label="区域编码" prop="childcode">
              <a-input :addon-before="formDatas.parentcode" v-model.trim="formDatas.childcode" />
            </a-form-model-item>
            <a-form-model-item label="区域名称" prop="areaname">
              <a-input v-model.trim="formDatas.areaname" />
            </a-form-model-item>
            <a-form-model-item label="区域类型" prop="areatype">
              <a-select v-model="formDatas.areatype" :disabled="selectedItem===null">
                <a-select-option v-if="formDatas.areatype<2" :value="1">省级</a-select-option>
                <a-select-option v-if="formDatas.areatype<3" :value="2">市级</a-select-option>
                <a-select-option :value="3">县区</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
  </page-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { addArea, modifyArea, deleteArea } from 'A/jcgn.js'
import {  geteChildListByParentcode,getDictByDicType } from 'A/xtpz.js'
import { getItemFromArrayByKey } from 'U'
import store from '@/store'
export default {
  name: 'adminDivision',
  components: {
  },
  data() {
    return {
      deptTypes: [],
      levelidList: [],
      userDeptOptions: [],
      breadcrumb: [
        {
          name: '基础功能管理',
          path: ''
        },
        {
          name: '行政区域划分',
          path: ''
        },
      ],
      adminDivisionTree: [],
      selectedKeys: [],
      selectedItem: null,
      formType: '',
      formDatas: {
        parentcode:"",
        childcode:"",
        areacode:"",
        areaname:"",
        areatype:"",
      },
      formRules: {
        childcode: [
          {required: true, message: '请输入区域编码'},
          {pattern: /^\d{2}$/, message: '请输入两位数字', trigger: 'blur'}
        ],
        areaname: [
          {required: true, message: '请输入区域名称'},
        ],
        areatype: [
          {required: true, message: '请选择区域类型'},
        ],
      },
      selectCoordinateVisible: false,
      peopleModalSelectedDeptid: '',
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
  },
  beforeRouteEnter(to, from, next) {
    let toTab = getItemFromArrayByKey(store.state.main.tabs, 'id', to.name);
    if(!toTab) {
      store.commit('delKeepAlive', to.name)
    }
    next()
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getDeptTree();
      // this.initAreaOptions();
    },
    getDeptTree() {
      let params = {
        parentcode:""
      };
      this.showLoading();
      geteChildListByParentcode(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.adminDivisionTree = res.item.map(item => {
            item.isLeaf = (item.areacode.length == 6 && item.areacode.substr(-2) != '00');
            item.scopedSlots = {title: 'customTreeNode'}
            return item;
          });
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    onLoadData(node) {
      return new Promise(resolve => {
        if (node.dataRef.children) {
          resolve();
          return;
        }
        let params = {
          parentcode: node.dataRef.areacode
        };
        this.showLoading();
        geteChildListByParentcode(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            node.dataRef.children = res.item.map(item => {
              item.isLeaf = (item.areacode.length == 6 && item.areacode.substr(-2) != '00');
              item.scopedSlots = {title: 'customTreeNode'}
              return item;
            });
            this.adminDivisionTree = [...this.adminDivisionTree];
            resolve();
          }
        }).catch(err => {
          this.hideLoading();
        })
      });
    },
    initAreaOptions() {
      let params = {
        dictype:2
      };
      getDictByDicType(params).then(res => {
        if(res && res.returncode == '0') {
          this.areaOptions = res.item.map(item => item);
        }
      })
    },
    addNewDept() {
      this.selectedItem = null;
      this.setFormData('addNew');
    },
    addChildQuick(node) {
      this.selectedItem = node.dataRef;
      this.addChild();
    },
    deleteQuick(node) {
      this.selectedItem = node.dataRef;
      this.deleteConfirm();
    },
    addChild() {
      if(this.selectedItem) {
        this.setFormData('add');
      }else {
        this.$message.info('请选择一条机构');
      }
    },
    selectDept(item) {
      let areacode = item[0];
      if(areacode) {
        this.selectedItem = getItemFromArrayByKey(this.adminDivisionTree, 'areacode', areacode);
        this.setFormData('edit');
      }else {
        this.resetFormData();
        this.selectedItem = null;
      }
    },
    setFormData(type) {
      this.formType = type;
      this.resetFormData();
      if('edit' == this.formType) {
        if(this.selectedItem) {
          const {areaname,areacode,areatype} = this.selectedItem
          if(areacode.length>2){
            this.formDatas.parentcode = areacode.slice(0,-2);
            this.formDatas.childcode = areacode.slice(-2);
          }else{
            this.formDatas.parentcode = "";
            this.formDatas.childcode = areacode;
          }
          this.formDatas.areaname = areaname;
          this.formDatas.areatype = areatype;
        }else {
          this.resetFormData();
        }
      }else {
        this.selectedKeys = [];
        if(this.selectedItem) {
          const {areacode,areatype} = this.selectedItem
          this.formDatas.parentcode = areacode
          this.formDatas.areatype = areatype+1
        }else {
          // 顶级机构
          this.formDatas.areatype = 1
        }
      }
    },
    resetFormData() {
      this.$refs.userDeptForm.resetFields();
      for(let key in this.formDatas) {
        this.formDatas[key] = '';
      }
    },
    save() {
      this.showLoading();
      const {parentcode,childcode} = this.formDatas
      let params = {
        ...this.formDatas,
        areacode:parentcode+childcode
      };
      if(this.formType == 'edit') {
        params.areaid = this.selectedItem.areaid
        modifyArea(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            let dept = getItemFromArrayByKey(this.adminDivisionTree, 'areaid', this.selectedItem.areaid);
            for(let key in dept) {
              if(this.formDatas[key]){
                dept[key] = this.formDatas[key]
              }
            }
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }else {
        addArea(params).then(async res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            let newNode = this.getDeptNode(res.areaid, {isLeaf: (params.areacode.length == 6 && params.areacode.substr(-2) != '00'), scopedSlots: {title: 'customTreeNode'}});
            if(this.selectedItem&&this.selectedItem.areaid){//有父parent
              let parentDept = getItemFromArrayByKey(this.adminDivisionTree, 'areaid', this.selectedItem.areaid);
              parentDept.isLeaf = false;
              if(!parentDept.children || !parentDept.children.length) {
                parentDept.children = [];
              }
              parentDept.children.push(newNode)
              this.adminDivisionTree = [...this.adminDivisionTree]
            }else {
              this.adminDivisionTree.push(newNode)
            }
            this.selectedItem = newNode
            this.setFormData('edit');
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    getDeptNode(areaid, obj) {
      const {parentcode,childcode} = this.formDatas
      return {
        ...this.formDatas,
        areaid,
        areacode:parentcode+childcode,
        ...obj
      }
    },
    deleteConfirm() {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete();
      }).catch(()=>{
      });
    },
    delete() {
      this.showLoading();
      let params = {
        areacode: this.selectedItem.areacode
      }
      deleteArea(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.$message.success('操作成功');
          if(this.selectedItem.areacode.length>2) {
            let parentDept = getItemFromArrayByKey(this.adminDivisionTree, 'areacode', this.selectedItem.areacode.slice(0,-2));
            parentDept.children = parentDept.children.filter(item => item.areaid!=this.selectedItem.areaid);
            this.adminDivisionTree = [...this.adminDivisionTree]
          }else {
            this.adminDivisionTree = this.adminDivisionTree.filter(item => item.areaid!=this.selectedItem.areaid);
          }
          this.resetFormData();
          this.selectedItem = null;
          this.formType = ""
          this.selectedKeys = [];
        }else {
          this.$message.error(res.errormsg||'操作失败');
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.dept-container {
  display: flex;
}
</style>